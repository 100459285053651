import * as React from 'react'

import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import Button from './button'
import Typeform from './typeform'

interface MDXProps {
  content: any
}

const MDX: React.FC<MDXProps> = ({ content, children, ...props }) => {
  const components = {
    button: Button,
    Typeform
  }
  return (
    <MDXProvider components={components}>
      <MDXRenderer {...props}>{content}</MDXRenderer>
      {children}
    </MDXProvider>
  )
}

export default MDX
