/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react'
import {
  AnalyticsProvider,
  useAnalytics
} from '@bestyled/contrib-analytics-google'

export const wrapRootElement = ({ element }, pluginOptions) => {
  return (
    <AnalyticsProvider tid={pluginOptions.trackingId}>
      {element}
    </AnalyticsProvider>
  )
}

export const wrapPageElement = ({ element, props: { location } }) => {
  return (
    <>
      <GatsbyRouterUpdated location={location} />
      {element}
    </>
  )
}

const GatsbyRouterUpdated: React.FC<{ location: Location }> = ({
  location
}) => {
  const { onRouteUpdate } = useAnalytics()

  React.useEffect(() => {
    onRouteUpdate({ location })
  }, [location])

  return null
}
