/* eslint-disable jsx-a11y/anchor-has-content */
import React, { ButtonHTMLAttributes, AnchorHTMLAttributes } from 'react'
import { Anchor } from '.'

const Button: React.FC<
  ButtonHTMLAttributes<any> &
    AnchorHTMLAttributes<any> & { withParams?: boolean; to?: string }
> = (props) => {
  // eslint-disable-next-line prefer-const
  let { href, to, ...rest } = props
  if (to) {
    href = to
  }

  if (
    typeof window !== 'undefined' &&
    window.location &&
    href &&
    props.withParams
  ) {
    if (window.location.search.match(/[?&]s=\w\w(?:&|$)/)) {
      let params = window.location.search.replace(/^\?/, '').split('&')
      let state = params.find((parma) => parma.startsWith('s='))!.split('=')[1]
      if (state === 'US' || state.length !== 2) {
        state = ''
      }
      params = params.filter((parma) => !parma.startsWith('s='))
      const search = params.join('&')
      href = `${href.replace(/\/$/, '')}/${state}?${search}`
    }
  }

  return (
    <Anchor
      aria-label="Press"
      className="btn v1"
      type="button"
      href={href}
      {...rest}
    />
  )
}

export default Button
