/**
 * This react helmt code is adapted from
 * https://themeteorchef.com/tutorials/reusable-seo-with-react-helmet.
 *
 * A great tutorial explaining how to setup a robust version of an
 * SEO friendly react-helmet instance.
 *
 *
 * Use the Helmt on pages to generate SEO and meta content!
 *
 * Usage:
 * <SEO
 *   title={title}
 *   description={description}
 *   image={image}
 * />
 *
 */

import * as React from 'react'
import Helmet from 'react-helmet'
import { SiteMetaData } from 'src/types'

interface HelmetProps {
  title?: string
  description?: string
  pathname?: string
  image?: string
  url?: string
  canonicalUrl?: string
  published?: string
  timeToRead?: string
  siteMetaData: SiteMetaData
}

const themeUIDarkModeWorkaroundScript = [
  {
    type: 'text/javascript',
    innerHTML: `
    (function() {
      try {
        var mode = localStorage.getItem('theme-color-mode');
        if (!mode) {
          localStorage.setItem('theme-color-mode', 'light');
        } else {
          document.body.classList.add('theme-' + mode);
        }
      } catch (e) {}
    })();
  `
  }
]

const SEO: React.FC<HelmetProps> = ({
  title,
  description,
  siteMetaData,
  children,
  url,
  image,
  published,
  timeToRead,
  canonicalUrl
}) => {
  const fullURL = (path: string) =>
    path ? `${siteMetaData.siteUrl}${path}` : siteMetaData.siteUrl

  url = url || fullURL('/')

  // If no image is provided lets looks for a default novela static image
  image = image || '/preview.jpg'

  // Checks if the source of the image is hosted on Contentful
  if (`${image}`.includes('ctfassets')) {
    image = `https:${image}`
  } else {
    image = fullURL(image)
  }

  const metaTags: any[] = [
    { charset: 'utf-8' },
    {
      'http-equiv': 'X-UA-Compatible',
      content: 'IE=edge'
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1'
    },
    {
      name: 'theme-color',
      content: '#fff'
    },
    { itemprop: 'name', content: title || siteMetaData.title },
    {
      itemprop: 'description',
      content: description || siteMetaData.description
    },
    { itemprop: 'image', content: image },
    { name: 'description', content: description || siteMetaData.description },

    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: siteMetaData.name },
    { name: 'twitter:title', content: title || siteMetaData.title },
    {
      name: 'twitter:description',
      content: description || siteMetaData.description
    },
    { name: 'twitter:creator', content: siteMetaData.social.twitter },
    {
      name: 'twitter:image',
      content: image
    },

    { property: 'og:title', content: title || siteMetaData.title },
    { property: 'og:url', content: url },
    { property: 'og:image', content: image },
    {
      property: 'og:description',
      content: description || siteMetaData.description
    },
    { property: 'og:site_name', content: siteMetaData.name }
  ]

  if (published) {
    metaTags.push({ name: 'article:published_time', content: published })
  }

  if (timeToRead) {
    metaTags.push({ name: 'twitter:label1', value: 'Reading time' })
    metaTags.push({ name: 'twitter:data1', value: `${timeToRead} min read` })
  }

  return (
    <Helmet
      title={title || siteMetaData.title}
      htmlAttributes={{ lang: 'en' }}
      script={themeUIDarkModeWorkaroundScript}
      meta={metaTags}
    >
      <link rel="canonical" href={canonicalUrl} />
      {children}
    </Helmet>
  )
}

export default SEO
